<template>
	<Link :href="'/'">
		<img
			src="/images/Logo-SalesNote-768x104.png"
			alt="SalesNote"
			:width="props.width"
			:height="props.height"
		/>
	</Link>
</template>

<script setup>
import { Link } from '@inertiajs/vue3';

const props = defineProps({
	height: {
		type: Number,
		default: undefined,
	},
	width: {
		type: Number,
		default: 208,
	},
});
</script>
